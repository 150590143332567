<template>
  <v-lazy>
    <v-card>
      <v-list>
        <v-list-item>
          <v-icon large color="secondary" class="ma-0 pa-0 pl-2"
            >mdi-certificate-outline
          </v-icon>

          <v-list-item-content class="ml-2">
            <v-list-item-title>
              <Fqdn :value="certificate.subject_fqdn" size="small"></Fqdn
            ></v-list-item-title>
            <v-list-item-subtitle
              ><span style="font-size:.8em;font-weight:bold;margin-left:5px;"
                >Issued by</span
              >
              <Fqdn :value="certificate.issuer_fqdn" size="x-small"></Fqdn
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn color="#eee" dark large icon class="mt-0" outlined
              ><v-icon
                small
                class="ma-0 pa-0"
                color="success"
                v-if="monitoring_settings"
                >mdi-bell-ring</v-icon
              >
              <v-icon small class="ma-0 pa-0" color="#666" v-else
                >mdi-bell-ring</v-icon
              >
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item dense class="mt-0 pt-0 pb-0">
          <ChipValue
            label="SHA1"
            :value="certificate.sha1"
            copy="true"
          ></ChipValue>
        </v-list-item>
        <v-list-item dense class="mt-0 pt-0 pb-0">
          <ChipValue
            label="SHA256"
            :value="certificate.sha256"
            copy="true"
          ></ChipValue>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-card
        style="font-size:.8em;"
        color="green accent-1"
        class="ma-2 pa-2"
        v-if="monitoring_settings"
      >
        <table>
          <tr>
            <td colspan="2">
              <strong>Notification Settings</strong>
            </td>
          </tr>
          <tr>
            <td>
              Expiration: {{ monitoring_settings.expired }}
              <br />
              &nbsp;&nbsp;-Hours ({{ monitoring_settings.hours }}) at
              {{ monitoring_settings.hours_at }}
              <br />
              &nbsp;&nbsp;-Percent ({{ monitoring_settings.percent }}%) at
              {{ monitoring_settings.percent_at }}
            </td>
            <td>
              Rekey: {{ monitoring_settings.rekey }}
              <br />
              Renew: {{ monitoring_settings.renewed }}
              <br />
              Revoke: {{ monitoring_settings.revoked }}
            </td>
          </tr>
        </table>
      </v-card>

      <v-card-actions>
        <v-btn style="width:150px;" color="primary" small>Add to Compare</v-btn>
        <v-btn style="width:150px;" color="primary" small
          >View on Compare</v-btn
        >
        <v-spacer />
        <v-btn
          style="width:150px;"
          color="success"
          small
          :href="`/cert/${certificate.sha1}`"
          >More Details</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-lazy>
</template>

<script>
import Fqdn from "./Fqdn";
import ChipValue from "./ChipValue";
//import axios from "axios";
//import authHeader from "../store/AuthHeader";

export default {
  name: "CertificateDialog",
  props: ["certificate", "monitoring_settings"],
  components: { Fqdn, ChipValue },
  data: function() {
    return {
      cert_details: null
    };
  },
  created() {
    if (this.monitoring_settings) {
      this.cert_details = this.monitoring_settings.certificate;
    } else {
      /*
      console.log("here");
      let self = this;
      axios
        .get(
          this.$store.getters.instance.api_url +
            "certificate-details/" +
            self.certificate.id +
            "/",
          {
            headers: authHeader(true)
          }
        )
        .then(response => {
          self.cert_details = response.data;
        });
       */
    }
  }
};
</script>

<style scoped></style>
