<template>
  <div>
    <v-btn
      :to="to_link"
      class="ma-0 pa-0"
      outlined
      color="primary"
      style="height:40px;width:20px !important;float:left;border-radius: 3px 0px 0px 3px;"
      dense
    >
      <v-badge
        color="primary"
        bottom
        left
        content="CA"
        offset-y="20"
        offset-x="21"
      >
        <v-icon large color="secondary" class="ma-0 pa-0 pl-2"
          >mdi-certificate-outline
        </v-icon>
      </v-badge>
    </v-btn>

    <v-dialog width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          x-small
          text
          v-on="on"
          class="mt-0"
          v-bind="attrs"
          style="float:right;height:40px;border: 1px solid #053768;border-left:0;border-radius: 0 3px 3px 0;"
        >
          <v-icon
            small
            class="ma-0 pa-0"
            color="success"
            v-if="monitoring_settings"
            aria-label="Settings"
            >mdi-bell-ring</v-icon
          >
          <v-icon
            aria-label="Settings"
            small
            class="ma-0 pa-0"
            color="#ddd"
            v-else
            >mdi-bell-ring</v-icon
          >
        </v-btn>
      </template>
      <test-dialog
        :certificate="certificate"
        :monitoring_settings="monitoring_settings"
      ></test-dialog>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import CertificateDialog from "./CertificateDialog";
export default {
  name: "CertTile",
  props: ["certificate", "local"],
  components: { "test-dialog": CertificateDialog },
  data: function() {
    return {
      shown: false,
      not_after: moment(this.certificate.valid_to).isAfter(moment()),
      not_before: moment(this.certificate.valid_from).isBefore(moment()),
      is_valid:
        moment(this.certificate.valid_to).isAfter(moment()) &&
        moment(this.certificate.valid_from).isBefore(moment()),
      cert_details: null
    };
  },
  computed: {
    to_link: function() {
      if (this.local) {
        return "/cert/" + this.certificate.id;
      } else {
        return "/cert/" + this.certificate.sha1;
      }
    },
    certificate_settings: function() {
      return this.$store.getters.certificate_settings;
    },
    monitoring_settings: function() {
      let sha1 = this.certificate.sha1;
      if (this.certificate && this.certificate_settings) {
        let test = this.certificate_settings.filter(function(value) {
          return value.certificate.sha1 === sha1;
        });
        if (test) {
          return test[0];
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    addToCompare: function() {}
  }
};
</script>
